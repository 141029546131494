<template>
  <v-row>
    <v-dialog v-model="dialog.active" persistent width="600">
      <v-card :loading="loading">
        <v-card-title v-if="!closeServiceLog"
          >Do you want to add a record into the boat's service history?
        </v-card-title>
        <v-card-title v-else>New entry for boat service history</v-card-title>

        <div v-if="!closeServiceLog">
          <v-card-text class="pl-6">
            If you have completed tasks related to the boats maintenance, please
            enter this information into the boats service history.
          </v-card-text>
          <v-card-actions>
            <v-btn class="ml-2" color="primary" text @click="onCancel()"
              >Cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="error" @click="onReject()"
              >No</v-btn
            >
            <v-btn
              color="primary"
              text
              :disabled="error"
              @click="closeServiceLog = true"
              >Yes</v-btn
            >
          </v-card-actions>
        </div>

        <div class="ml-2" v-else>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              Fill in information about any completed tasks related to the
              boat's maintenance, and if applicable attach relevant
              documentation (e.g. work order/invoice).
            </v-card-text>
            <v-card-text>
              <date-picker
                :no-style="true"
                :provider-date.sync="selectedDate"
                date-label="Date of service"
                :prepend-icon="'mdi-calendar'"
              ></date-picker>
              <v-file-input
                color="primary"
                v-model="selectedFiles"
                label="Attach photos/documents"
                multiple
                prepend-icon="mdi-paperclip"
                :rules="[rules.file]"
              ></v-file-input>
              <v-row
                v-for="(file, index) in alreadySelectedFiles"
                :key="index"
                no-gutters
              >
                <v-col cols="9" class="ml-4">
                  <v-btn
                    icon
                    :href="file.file_link"
                    class="text-none subtitle-2"
                    ><v-icon class="mr-2">mdi-file-image</v-icon
                    >{{ file.title }}</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2"
                  ><v-btn text color="red" @click="removeAttachment(index)"
                    ><v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="pl-12" v-if="reports.length !== 0">
              Suggested sources:
            </v-card-text>

            <v-card-text class="pl-12" v-else>
              No suggested sources available
            </v-card-text>

            <div v-for="(report, index) in reports" :key="index">
              <v-list flat class="ml-8 pb-0">
                <v-list-item class="mb-0 pb-0">
                  <template>
                    <v-list-item-action class="my-0 py-0">
                      <v-checkbox
                        v-model="selectedReports"
                        :value="report.uuid"
                        color="primary"
                        multiple
                        :disabled="report.status !== 'COMPLETED'"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content class="my-0 py-0">
                      <v-list-item-title>{{ report.name }}</v-list-item-title>
                    </v-list-item-content>

                    <v-chip v-if="report.status !== 'COMPLETED'"
                      >Not completed</v-chip
                    >
                    <v-list-item-action class="my-0 py-0 mr-1">
                      <v-btn icon @click="openTaskDetails(report)">
                        <v-icon color="grey lighten-1" v-if="!report.active"
                          >mdi-chevron-down</v-icon
                        >
                        <v-icon color="grey lighten-1" v-else
                          >mdi-chevron-up</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <div v-if="report.active">
                  <div
                    v-for="(checklist, index) in report.checklists"
                    :key="index"
                  >
                    <v-row no-gutters :class="{ 'mt-2': index > 0 }">
                      <v-list-item-action class="mt-0 pt-0">
                      </v-list-item-action>
                      <span class="body-2 grey--text">{{
                        checklist.name
                      }}</span>
                    </v-row>
                    <v-row
                      no-gutters
                      v-for="(subtask, index) in checklist.subtasks"
                      :key="index"
                    >
                      <v-list-item-action class="mt-0 pt-0">
                      </v-list-item-action>
                      <span class="caption">
                        <span
                          v-if="subtask.completed !== null"
                          class="grey--text"
                          >☒
                        </span>
                        <span v-else class="grey--text">☐ </span>
                        <span class="grey--text">
                          {{ subtask.description }}
                        </span>
                      </span>
                    </v-row>
                  </div>
                </div>
              </v-list>
            </div>

            <v-card-text>
              <v-textarea
                class="mt-4"
                v-model="shortContent.string"
                label="Summary of completed tasks"
                color="primary"
                rows="2"
                counter="255"
                :rules="[rules.length(255), rules.description, rules.summary]"
                prepend-icon="mdi-text-box"
              />
              <warning-dialog
                :dialog="showEditingWarning.short"
                :text="showEditingWarning.text"
                :title="showEditingWarning.title"
                @agree="handleWarningDialogOutput"
                confirm="Yes, override my edits"
                reject="No, keep my edits"
                v-show="false"
              >
                <template v-slot:text>
                  <v-card-text
                    >Click YES to regenerate the description based on
                    selection.</v-card-text
                  >
                  <v-card-text class="mb-2"
                    >Click NO to preserve task description as is.
                  </v-card-text>
                </template>
              </warning-dialog>
              <v-textarea
                v-model="longContent.string"
                label="Full description of completed tasks (optional)"
                color="primary"
                auto-grow
                counter="6000"
                :rules="[rules.length(6000)]"
                prepend-icon="mdi-text-box"
              />
              <warning-dialog
                :dialog="showEditingWarning.long"
                :text="showEditingWarning.text"
                :title="showEditingWarning.title"
                @agree="handleWarningDialogOutput"
                confirm="Yes, override my edits"
                reject="No, keep my edits"
                v-show="false"
                ><template v-slot:text>
                  <v-card-text
                    >Click YES to regenerate the description based on
                    selection.</v-card-text
                  >
                  <v-card-text class="mb-2"
                    >Click NO to preserve task description as is.
                  </v-card-text>
                </template></warning-dialog
              >
            </v-card-text>

            <v-card-actions>
              <div class="text-center">
                <v-menu
                  v-model="menuCancel"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                  permanent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on">
                      Cancel
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-text
                      >Are you sure you want to cancel dialog?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn text @click="menuCancel = false">
                        Back
                      </v-btn>
                      <v-btn color="primary" text @click="onCancel()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
              <v-spacer></v-spacer>
              <div class="text-center">
                <v-menu
                  v-model="menuSave"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                  permanent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" text v-bind="attrs" v-on="on">
                      Save
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-text>Are you sure you want to save? </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn text @click="menuSave = false">
                        Back
                      </v-btn>
                      <v-btn color="primary" text @click="onSave()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "@/utils/api.js";
import DatePicker from "@/components/pickers/DatePicker";
import { mapGetters, mapState } from "vuex";
import snackbar from "@/utils/snackbar.js";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
export default {
  props: ["dialog", "threadMessages"],
  components: {
    DatePicker,
    WarningDialog,
  },
  data: () => ({
    loading: true,
    error: false,
    valid: true,
    rules: {},
    selectedFiles: [],
    selectedReports: [],
    selectedDate: null,
    reports: [],
    alreadySelectedFiles: [],
    description: "",
    serviceLogUuid: null,
    closeServiceLog: false,
    menuSave: false,
    menuCancel: false,
    longContent: { string: "", manual: false },
    shortContent: {},
    showEditingWarning: {},
    tempLongContent: "",
    tempShortContent: "",
  }),
  created() {
    this.setInitialData();
    this.selectedDate = this.$moment();
    this.loading = true;
    api
      .getAvailableServiceLogReports(
        this.dialog.boat.uuid,
        this.threadMessages[0].uuid
      )
      .then((resp) => {
        this.reports = resp.data.map((data) => {
          if (data.is_linked && data.status === "COMPLETED") {
            this.selectedReports.push(data.uuid);
          }
          return { ...data, active: false };
        });

        if (this.dialog.isEdit) {
          this.description = this.dialog.note.content;
          this.dialog.note.attachments.forEach((element) => {
            this.alreadySelectedFiles.push(element);
          });
        }
        this.threadMessages.forEach((element) => {
          element.attachments.forEach((item) => {
            if (item.service_log_entry) {
              this.serviceLogUuid = item.service_log_entry.uuid;
            }
          });
        });
        this.loading = false;
      })
      .catch((err) => {
        this.error = true;
        this.loading = false;
      });
  },
  computed: {},

  watch: {
    selectedReports(newValue, oldValue) {
      if (!this.longContent.manual) {
        this.setLongTextSelect();
      } else {
        this.showEditingWarning.long = true;
      }

      if (!this.shortContent.manual) {
        this.setShortTextSelect();
      } else {
        this.showEditingWarning.short = true;
      }
    },
    "longContent.string": function(newValue) {
      if (JSON.stringify(newValue) === JSON.stringify(this.tempLongContent)) {
        return;
      } else if (!this.longContent.manual) {
        this.setLongTextChange();
      }
    },
    "shortContent.string": function(newValue) {
      if (JSON.stringify(newValue) === JSON.stringify(this.tempShortContent)) {
        return;
      } else if (!this.shortContent.manual) {
        this.setShortTextChange();
      }
    },
  },

  methods: {
    setInitialData() {
      this.rules = {
        length: (len) => (v) =>
          (v || "").length <= len ||
          `Invalid character length, required maximum of ${len}`,
        summary: (v) =>
          JSON.stringify(v) !== JSON.stringify(`Maintenance done for: `) ||
          `Summary of completed tasks is required`,
        description: (v) => !!v || `Summary of completed tasks is required`,
        file: (v) => !!v || `File is required`,
      };

      this.shortContent = {
        string: `Maintenance done for:`,
        items: [],
        manual: false,
      };

      this.showEditingWarning = {
        short: false,
        long: false,
        text: `You've made manual modifications to Summary/Full details description.`,
        title: `Do you want to regenerate Summary/Full details?`,
      };
    },
    setShortTextSelect() {
      const reportsWithCrmItemsMap = new Map();

      const filteredReports = this.reports
        .filter((report) => this.selectedReports.includes(report.uuid))
        .flatMap((report) =>
          report.checklists.map((checklist) => {
            if (
              typeof checklist.crm_boat_item !== "undefined" &&
              checklist.crm_boat_item !== null
            ) {
              reportsWithCrmItemsMap.set(report.uuid, report);
            }
            return checklist.crm_boat_item;
          })
        )
        .filter((item) => !!item);

      let reportsWithCrmItemsArray = filteredReports.map((item) => {
        return item.crm_item.name;
      });

      let reportsWithoutCrmItemsArray = this.reports
        .filter((item) => {
          return (
            ![...reportsWithCrmItemsMap.keys()].includes(item.uuid) &&
            this.selectedReports.includes(item.uuid)
          );
        })
        .map((item) => {
          return item.name;
        });

      //TODO TRANSLATION: handle edge case
      let string =
        "Maintenance done for: " +
        [...reportsWithCrmItemsArray, ...reportsWithoutCrmItemsArray].join(
          ", "
        );
      this.tempShortContent = string;
      this.shortContent.string = string;
      this.shortContent.items = reportsWithCrmItemsArray;
    },

    setLongTextSelect() {
      let reports = this.reports
        .filter((report) => {
          return this.selectedReports.includes(report.uuid);
        })
        .flatMap((report) => {
          return report.checklists.flatMap((checklist) => {
            return {
              descriptions: checklist.subtasks.map((subtask) => {
                return {
                  description: subtask.description,
                  completed: subtask.completed,
                };
              }),
              name:
                checklist.name + this.formatCrmItem(checklist.crm_boat_item),
            };
          });
        });

      let string = "";
      reports.forEach((report) => {
        string += `${report.name} \n`;
        report.descriptions.forEach((element) => {
          string += `${element.completed !== null ? "☒ " : "☐ "}${
            element.description
          } \n`;
        });
        string += `\n`;
      });
      this.tempLongContent = string;
      this.longContent.string = string;
    },

    formatCrmItem(item) {
      if (typeof item === "undefined" || item === null) return "";

      return ` (${item.crm_item.formatted_name})`;
    },
    setLongTextChange() {
      this.longContent.manual = true;
    },
    setShortTextChange() {
      this.shortContent.manual = true;
    },
    handleWarningDialogOutput(status) {
      if (status) {
        this.longContent.manual = false;
        this.shortContent.manual = false;
        this.setLongTextSelect();
        this.setShortTextSelect();
      }
      this.showEditingWarning.long = false;
      this.showEditingWarning.short = false;
      return;
    },
    setShortContent(content) {
      this.shortContent = content;
    },
    openTaskDetails(report) {
      this.$set(report, "active", !report.active);
    },
    removeAttachment(index) {
      this.alreadySelectedFiles.splice(index, 1);
    },
    onCancel() {
      this.$emit("saveDocument", false);
    },
    onReject() {
      this.$emit("saveDocument", true);
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        snackbar.error(
          "The form is incomplete. Please complete the form before saving"
        );
        return;
      }

      let promises = this.selectedFiles.map((element) => {
        let fileName = element.name.substring(0, element.name.lastIndexOf("."));
        let fileType = element.name.substring(
          element.name.lastIndexOf(".") + 1,
          element.name.length
        );

        let data = {
          file_type: fileType,
          file_name: fileName,
          source: "PLATFORM",
        };

        return new Promise((resolve, reject) => {
          api.uploadAttachments(data).then((resp) => {
            resolve({
              response: resp.data,
              file_resource_uuid: resp.data.file_resource_uuid,
              file: element,
            });
          });
        });
      });

      Promise.all(promises).then((values) => {
        let file_resource_uuids = values.map(
          (value) => value.file_resource_uuid
        );

        let promises = values.map((value) => {
          let fileData = new FormData();
          fileData.append("key", value.response.fields.key);
          fileData.append("policy", value.response.fields.policy);
          fileData.append(
            "x-amz-algorithm",
            value.response.fields["x-amz-algorithm"]
          );
          fileData.append(
            "x-amz-credential",
            value.response.fields["x-amz-credential"]
          );
          fileData.append("x-amz-date", value.response.fields["x-amz-date"]);
          fileData.append(
            "x-amz-signature",
            value.response.fields["x-amz-signature"]
          );
          fileData.append("file", value.file);

          return new Promise((resolve, reject) => {
            api
              .uploadAttachmentFile(value.response.url, fileData)
              .then((resp) => {
                resolve({
                  data: resp.data,
                  file_resource_uuids: file_resource_uuids,
                });
              });
          });
        });

        Promise.all(promises).then((values) => {
          let data = {
            title: "OTHER",
            content: this.shortContent.string,
            content_long: this.longContent.string,
            completion_date: this.selectedDate,
            crm_internal_task_uuids: this.selectedReports,
            crm_message_uuid: this.threadMessages[0].uuid,
            file_resource_uuids: file_resource_uuids,
          };

          api.setServiceLogReports(this.dialog.boat.uuid, data).then((resp) => {
            this.$emit("saveDocument", true);
            snackbar.success("The boat history has been successfully updated");
          });
        });
      });
    },
  },
};
</script>
