<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card v-if="note.active" tile color="grey lighten-3">
      <v-list-item class="grow">
        <v-list-item-avatar color="grey darken-3">
          <v-icon v-if="note.editedNote.type === 'NOTE'" class="white"
            >mdi-lock</v-icon
          >
          <v-icon v-if="note.editedNote.type === 'EMAIL'" class="white"
            >mdi-email</v-icon
          >
        </v-list-item-avatar>

        <v-list-item-content
          style="padding-bottom: 0px"
          v-if="note.editedNote.type === 'NOTE'"
        >
          <v-list-item-title>
            <v-select
              v-model="recipient"
              :items="noteAssigneeOptions"
              label="Select assignee*"
              required
              menu-props="auto"
              item-value="username"
              item-text="username"
              color="primary"
              :rules="assigneeRule"
              :disabled="loading"
            ></v-select>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content
          style="padding-bottom: 0px"
          v-if="note.editedNote.type === 'EMAIL'"
        >
          <v-list-item-title>
            <v-select
              v-model="selectedMember"
              :items="memberOptions"
              menu-props="auto"
              item-text="username"
              label="Select assignee*"
              return-object
              color="primary"
            ></v-select>
          </v-list-item-title>
        </v-list-item-content>

        <v-row align="center" justify="end">
          <time class="float-right mr-3 ml-2">
            <v-list-item-subtitle v-if="note.editedNote.type === 'NOTE'"
              >(this note will not be visible to customer)</v-list-item-subtitle
            >
          </time>
        </v-row>
      </v-list-item>
      <v-list-item class="grow">
        <v-list-item-avatar></v-list-item-avatar>
        <v-list-item-content style="padding-bottom: 0px">
          <v-list-item-subtitle>
            <v-textarea
              v-model="content"
              outlined
              :label="placeholder"
              color="primary"
              auto-grow
              :rules="contentLengthRule"
              :disabled="loading"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="selectedFiles.length">
        <v-list-item-avatar></v-list-item-avatar>

        <v-row justify="end">
          <v-list-item
            v-for="(selectedFile, index) in selectedFiles"
            :key="index"
            ><v-btn
              :disabled="loading"
              text
              class="pl-0 text-none grey--text text--darken-1"
              @click="openAttachment(selectedFile)"
            >
              <v-icon class="mr-1"> mdi-file-document </v-icon>
              {{ selectedFile.name }}
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mt-1"
              @click="removeAttachment(index)"
              :disabled="loading"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-list-item>
        </v-row>
      </v-list-item>

      <v-list-item>
        <v-row align="center" justify="end" class="mb-2">
          <v-list-item-avatar></v-list-item-avatar>
          <template>
            <v-btn
              text
              class="float-right"
              :disabled="loading"
              @click="addAttachment"
            >
              <v-icon class="mr-1">mdi-paperclip</v-icon>
              Attachment
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
              id="attachmentImport"
              :disabled="loading"
            />
          </template>
          <v-spacer></v-spacer>
          <div class="float-right mr-3">
            <v-list-item-subtitle>
              <v-btn text @click="closeNote()" class="assignee-gray"
                >Cancel</v-btn
              >
              <v-btn
                text
                @click="saveNote()"
                :loading="loading"
                class="assignee-gray"
                >{{ postText }}</v-btn
              >
            </v-list-item-subtitle>
          </div>
        </v-row>
      </v-list-item>
    </v-card>
  </v-form>
</template>

<script>
import debounce from "@/plugins/debounce";
import api from "@/utils/api.js";
import snackbar from "@/utils/snackbar";

export default {
  props: ["note", "threadUuid", "threadAssignee", "threadPartner"],
  data: () => ({
    noteAssigneeOptions: [],
    contentLengthRule: [],
    assigneeRule: [],
    recipient: "",
    content: "",
    valid: true,
    isSelecting: false,
    selectedFiles: [],
    loading: false,
  }),
  created() {
    this.setInitialData();
    this.loadAssignee(null, this);
    if (this.note.editedNote.type === "EMAIL") {
      this.noteAssigneeOptions.push(this.note.editedNote.recipient);
      this.recipient = this.note.editedNote.recipient;
    } else {
      this.recipient = this.note.editedNote.recipient;
      this.content = this.note.editedNote.content;
    }

    this.selectedMember = this.memberOptions[0] || {};
  },

  computed: {
    placeholder() {
      return this.note.editedNote.type === "NOTE"
        ? "Note to assignee"
        : "Message to customer";
    },
    postText() {
      return this.note.editedNote.type === "NOTE" ? "Save" : "Send";
    },
    selectedMember: {
      get: function() {
        return this.memberOptions[0];
      },
      set: function(value) {
        this.note.editedNote.primary_recipient = value.username;
        this.note.editedNote.primary_recipient_uuid = value.uuid;
      },
    },
    memberOptions() {
      const findCustomer = this.note.members.find((item) => {
        return item.type === "CUSTOMER";
      });

      if (
        typeof findCustomer !== "undefined" &&
        Object.keys(findCustomer).length !== 0
      ) {
        return [findCustomer];
      }

      const findRecipient = this.note.members.find((item) => {
        return item.type === "PARTNER_RECIPIENT" && !item.is_self;
      });

      if (
        typeof findRecipient !== "undefined" &&
        Object.keys(findRecipient).length !== 0
      ) {
        return [findRecipient];
      }

      const findReceiver = this.note.members.find((item) => {
        return item.type === "PARTNER_SENDER";
      });

      if (
        typeof findReceiver !== "undefined" &&
        Object.keys(findReceiver).length !== 0
      ) {
        return [findReceiver];
      }

      console.error(JSON.stringify(this.note.members));
      return [];
    },
  },

  methods: {
    setInitialData() {
      this.assigneeRule = [(v) => !!v || "Assignee is required"];

      this.contentLengthRule = [(v) => !!v || "Content is required"];
    },
    addAttachment() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFiles.push(e.target.files[0]);
      document.getElementById("attachmentImport").value = "";
    },

    openAttachment(selectedFile) {
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = function() {
        let w = window.open("about:blank");
        let image = new Image();
        image.src = reader.result;
        setTimeout(function() {
          w.document.write(image.outerHTML);
        }, 0);
      };
    },

    removeAttachment(index) {
      this.selectedFiles.splice(index, 1);
    },

    closeNote() {
      this.note.active = false;
    },

    saveNote() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let firstLevelOfPromises = this.selectedFiles.map((element) => {
          let lastIndexOfChar = element.name.lastIndexOf(".");
          let fileName = element.name.substring(0, lastIndexOfChar);
          let fileType = element.name.substring(
            lastIndexOfChar + 1,
            element.name.length
          );
          let data = {
            file_type: fileType,
            file_name: fileName,
            source: "PLATFORM",
            partner_uuid: this.threadPartner.uuid,
            file_purpose: "message-attachment",
          };
          return new Promise((resolve, reject) => {
            api.uploadMessageAttachment(data).then((resp) => {
              resolve({
                response: resp.data,
                file: element,
                title: element.name,
              });
            });
          });
        });
        Promise.all(firstLevelOfPromises).then((firstLevelValues) => {
          let secondLevelOfPromises = firstLevelValues.map((item) => {
            let fileData = new FormData();
            fileData.append("key", item.response.fields.key);
            fileData.append("policy", item.response.fields.policy);
            fileData.append(
              "x-amz-algorithm",
              item.response.fields["x-amz-algorithm"]
            );
            fileData.append(
              "x-amz-credential",
              item.response.fields["x-amz-credential"]
            );
            fileData.append("x-amz-date", item.response.fields["x-amz-date"]);
            fileData.append(
              "x-amz-signature",
              item.response.fields["x-amz-signature"]
            );
            fileData.append("file", item.file);
            return new Promise((resolve, reject) => {
              api
                .uploadMessageAttachmentFile(item.response.url, fileData)
                .then((resp) => {
                  resolve({
                    aws_key: item.response.fields.key,
                    title: item.title,
                  });
                });
            });
          });
          Promise.all(secondLevelOfPromises).then((secondLevelValues) => {
            this.saveTicket(secondLevelValues);
          });
        });
      } else {
        snackbar.error("Please fill required fields");
        window.scrollTo(0, document.body.scrollHeight);
      }
    },
    saveTicket(uploadResponse) {
      //NOT CONSISTENT ON BACKEND..
      this.note.editedNote.attachment_keys = uploadResponse;
      // typeof this.note.editedNote.primary_recipient === "undefined" &&
      if (this.note.editedNote.type === "NOTE") {
        this.note.editedNote.primary_recipient = this.recipient;
        this.note.editedNote.primary_recipient_uuid =
          this.noteAssigneeOptions.find((item) => {
            return item.username === this.recipient;
          }).uuid || null;
      }

      const backupType = this.note.editedNote.type;
      if (this.note.editedNote.postType !== null) {
        this.note.editedNote.type = this.note.editedNote.postType;
      }

      this.note.editedNote.content = this.content;
      delete this.note.editedNote.recipient;

      if (this.note.editedNote.uuid) {
        api
          .updateTicketNote(
            this.threadUuid,
            this.note.editedNote.uuid,
            this.note.editedNote
          )
          .then((resp) => {
            this.$emit("reload-messages", true);
            this.closeNote();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.note.editedNote.type = backupType;
          });
      } else {
        api
          .createTicketNote(this.threadUuid, this.note.editedNote)
          .then((resp) => {
            this.$emit("reload-messages", true);
            this.closeNote();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.note.editedNote.type = backupType;
          });
      }
    },
    loadAssignee: debounce((event, self) => {
      api.assigneeAvailableUsers(self.threadUuid).then((resp) => {
        resp.data.forEach((element) => {
          self.noteAssigneeOptions.push(element);
        });
      });
    }, 300),
  },
};
</script>

<style scoped>
.v-text-field.v-text-field--enclosed {
  margin-top: 5px;
  margin-bottom: 0px;
}
</style>
