<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    color="primary"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="{ 'pt-0': noStyle, 'mt-0': noStyle }"
        :value="formattedDate"
        :label="dateLabel"
        color="primary"
        readonly
        persistent-hint
        :hint="hint"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :prepend-icon="prependIcon"
        :clearable="clearable"
        @click:clear="handleClearable"
      />
    </template>
    <v-date-picker
      v-model="formattedDate"
      color="primary"
      @input="menu = false"
      @change="menu = false"
      :min="noPastDate"
    />
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: [
    "providerDate",
    "dateLabel",
    "hint",
    "noStyle",
    "preventPastDate",
    "disabled",
    "prependIcon",
    "clearable",
  ],
  computed: {
    noPastDate() {
      return this.preventPastDate
        ? new Date().toISOString().substring(0, 10)
        : "";
    },
    formattedDate: {
      get: function() {
        if (this.providerDate) {
          return new Date(this.providerDate).toISOString().substring(0, 10);
        } else {
          return "";
        }
      },
      set: function(newValue) {
        this.$emit(
          "update:providerDate",
          newValue !== "" ? new Date(newValue).toISOString() : ""
        );
      },
    },
  },
  data: () => ({
    menu: false,
    modal: false,
  }),
  methods: {
    handleClearable() {
      this.formattedDate = "";
    },
  },
};
</script>
