<template>
  <v-row>
    <v-dialog v-model="dialogActive" persistent width="600">
      <v-card>
        <v-card-title>Assign new agent</v-card-title>
        <v-card-text class="mt-2">
          <v-autocomplete
            autocomplete="new-password"
            v-model="selectedAssignee"
            label="selectAssignee"
            :items="assigneeOptions"
            item-text="username"
            item-value="username"
            :search-input.sync="assigneeSearch"
            color="primary"
            @input.native="loadAssignee($event, $data)"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <!-- <v-card-text>Agent will receive email</v-card-text> -->
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onCancel()"
            data-cy="importServiceProtocolDialogCancelBtn"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="onSave()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import debounce from "@/plugins/debounce";
import api from "@/utils/api.js";
export default {
  props: ["dialog"],
  data: () => ({
    selectedAssignee: null,
    assigneeOptions: [],
    assigneeSearch: null,
  }),

  created() {
    this.loadAssignee(null, this);
  },
  computed: {
    dialogActive() {
      return !!this.dialog.active;
    },
  },
  methods: {
    onSave() {
      this.$emit("assignee", { user_email: this.selectedAssignee });
    },
    onCancel() {
      this.$emit("assignee", false);
    },
    loadAssignee: debounce((event, self) => {
      api.assigneeAvailableUsers(self.dialog.uuid).then((resp) => {
        resp.data.forEach((element) => {
          self.assigneeOptions.push(element);
        });
      });
    }, 300),
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
