<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="410">
      <v-card>
        <v-card-title class="headline"> {{ title }}</v-card-title>
        <v-card-text> {{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!disableDeny"
            color="primary"
            text
            @click="onDisagree()"
            >{{ deny }}</v-btn
          >
          <v-btn
            v-if="!disableApprove"
            color="primary"
            text
            @click="onAgree()"
            >{{ approve }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: [
    "dialog",
    "item",
    "title",
    "text",
    "confirm",
    "reject",
    "disableReject",
    "disableConfirm",
  ],
  computed: {
    approve() {
      return typeof this.confirm == "undefined" ? "OK" : this.confirm;
    },
    deny() {
      return typeof this.reject == "undefined" ? "Cancel" : this.reject;
    },
    disableApprove() {
      return typeof this.disableReject == "undefined"
        ? false
        : this.disableConfirm;
    },
    disableDeny() {
      return typeof this.reject == "undefined" ? false : this.disableReject;
    },
  },
  methods: {
    onAgree() {
      this.$emit("agree", true);
    },
    onDisagree() {
      this.$emit("agree", false);
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
