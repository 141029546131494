<template>
  <v-card
    v-if="message"
    flat
    :key="message.content"
    :class="{ 'grey lighten-3': message.subtype === 'NOTE' }"
    class="pt-2"
  >
    <router-link
      v-if="message.type === 'task'"
      style="cursor: pointer"
      :to="{
        name: 'tasksDetails',
        params: { id: message.crm_internal_task.uuid },
      }"
    >
      <v-list-item class="mb-4 mt-2">
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-wrench </v-icon>
        </v-list-item-avatar>

        <v-list-item-content class="ml-4">
          <v-list-item-title>
            Task #{{ message.crm_internal_task.task_identifier }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  message.crm_internal_task.name
                }}</span>
              </template>
              <span style="display: block; max-width: 400px">
                Description: {{ message.crm_internal_task.task_identifier }}
              </span>
            </v-tooltip></v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-subtitle> Progress:</v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ getPercentageOfCompletion(message.crm_internal_task) }}%
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-subtitle> Task status:</v-list-item-subtitle>
          <v-list-item-subtitle> {{ getStatus(message) }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-subtitle> Assignee:</v-list-item-subtitle>
          <v-list-item-subtitle>
            <span
              v-for="(assignee, index) in getTaskAssignee(
                message.crm_internal_task.assignees
              )"
              :key="index"
            >
              {{ assignee }} <br />
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-chip outlined>
            Show Details
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </router-link>

    <div v-if="message.type === 'message'">
      <v-list-item class="pt-0 mt-0">
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark v-if="message.subtype === 'NOTE'"
            >mdi-lock</v-icon
          >
          <v-icon class="grey lighten-1" dark v-else>mdi-email</v-icon>
        </v-list-item-avatar>

        <v-card-title class="subtitle-1">
          <span> {{ message.sender.username }} <b>to</b> {{ recipient }} </span>
        </v-card-title>

        <v-row align="center" justify="end">
          <!-- <v-btn color="grey darken-1" text @click="editMessage">
            <v-icon v-if="message.subtype === 'NOTE'">mdi-pencil</v-icon>
          </v-btn> -->
          <time class="float-right mr-3" :datetime="message.created">
            <v-list-item-subtitle>{{
              message.created | moment("from")
            }}</v-list-item-subtitle>
          </time>
        </v-row>
      </v-list-item>

      <v-list-item class="grow mt-3">
        <v-list-item-avatar></v-list-item-avatar>

        <v-list-item-content>
          <v-card-subtitle
            class="mb-3"
            style="white-space: pre-line"
            v-html="cleanMessage"
          ></v-card-subtitle>
        </v-list-item-content>
      </v-list-item>

      <div v-if="message.attachments.length !== 0">
        <v-list-item class="grow mt-3">
          <v-list-item-avatar></v-list-item-avatar>

          <div v-for="attachment in message.attachments" :key="attachment.uuid">
            <!-- FILE RESOURCE ATTACHMENT -->
            <v-btn
              v-if="attachment.file_resource && attachment.file_resource.file"
              :href="attachment.file_resource.file"
              style="padding-left: 0px"
              text
              color="grey darken-1"
            >
              <v-icon>mdi-file-document</v-icon>
              <span class="ml-1" style="text-transform: none">{{
                attachment.file_resource.title
              }}</span>
            </v-btn>
            <v-btn
              v-if="attachment.file_resource && !attachment.file_resource.file"
              :href="attachment.file_resource.link"
              style="padding-left: 0px"
              text
              color="grey darken-1"
              class="ml-4"
            >
              <v-icon>mdi-file-document</v-icon>
              <span class="ml-1" style="text-transform: none">{{
                attachment.file_resource.title
              }}</span>
            </v-btn>
          </div>
        </v-list-item>
      </div>
    </div>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "Message",
  props: ["message", "thread"],
  data: () => ({
    statusOptions: [],
  }),
  computed: {
    recipient() {
      if (this.message.recipient && !this.message.recipient.indexOf("['")) {
        return this.message.recipient.slice(
          2,
          this.message.recipient.length - 2
        );
      } else {
        return this.message.recipient;
      }
    },
    cleanMessage() {
      return this.$sanitize(this.message.content);
    },
  },
  created() {
    this.setInitalData();
  },
  methods: {
    setInitalData() {
      this.statusOptions = [
        {
          status: "NOT_STARTED",
          name: "Not started",
        },
        {
          status: "IN_PROGRESS",
          name: "In progress",
        },
        {
          status: "COMPLETED",
          name: "Completed",
        },
      ];
    },
    // openTask(uuid) {
    //   this.$router.push({
    //     name: "tasksDetails",
    //     params: { id: uuid },
    //   });
    // },
    getPercentageOfCompletion(task) {
      if (task.all_subtasks === 0 && task.done_subtasks === 0) {
        return 0;
      }
      return Math.round((task.done_subtasks * 100) / task.all_subtasks);
    },
    getStatus(status) {
      return this.statusOptions.find(
        (item) => item.status === status.crm_internal_task.status
      ).name;
    },

    getTaskAssignee(assignees) {
      return !!assignees && assignees.length !== 0
        ? assignees.map((assignee) => {
            return assignee.email;
          })
        : ["No user"];
    },

    editMessage() {
      this.$emit("edit-message", this.message);
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
